import { ServiceLineObject } from "../../../context/canvas/types";
import {
  ImagePaths,
  ORTHO_IMAGE_TYPE,
  OrthoCanvasInformationObject,
  OrthoClaimInformation,
  OrthoImageDetails,
  OrthoImageObj,
  orthoImageTypeToHyphenated,
} from "../../../context/ortho/types";
import { userService } from "../../../services";
import { formatDate } from "../../../components/new-design/utility/helpers";
import {
  getColorMatchedWithResult,
  getConvertedOverjetReviewCode,
  getOJRRIcon,
  getReviewResult,
} from "../../../components/new-design/canvas/canvas-helper";
import { GenericObject } from "../../../defs";

export class OrthoClaim {
  canvasInformation: OrthoCanvasInformationObject;

  constructor(canvasInformation: OrthoCanvasInformationObject) {
    this.canvasInformation = canvasInformation;
  }

  getOrthoClaimInformation() {
    let serviceLines =
      this.canvasInformation?.currentClaimInformation?.serviceLinesList?.map(
        (line) => this.enhanceServiceLineDetails(line)
      ) || [];

    let [supportedServiceLines, unSupportedServiceLines] =
      this.splitSupportAndUnSupportedServiceLines(serviceLines);
    let activeServiceLine =
      supportedServiceLines.length > 0 ? supportedServiceLines[0] : undefined;
    return {
      id: this.canvasInformation?.id,
      claimId: this.canvasInformation?.claimId,
      patientId: this.canvasInformation?.patientInfo?.patientId,
      activeServiceLine: activeServiceLine,
      processingState: this.canvasInformation?.processingState,
      dateOfService: activeServiceLine?.dateOfService,
      ruleExecutionId: activeServiceLine?.ruleExecutionId,
      supportedServiceLines: supportedServiceLines,
      unSupportedServiceLines: unSupportedServiceLines,
      orthoFindings: this.canvasInformation?.claimLevelOjrrFindings,
    } as OrthoClaimInformation;
  }

  splitSupportAndUnSupportedServiceLines(
    serviceLinesList: Array<ServiceLineObject> | undefined
  ) {
    let supportedServiceLines: ServiceLineObject[] = [];
    let unSupportedServiceLines: ServiceLineObject[] = [];
    const unsupportedDecisionCodes = "P999";
    const { procedureCodeConfig } = userService.getClientConfig();
    const orthoCodes = procedureCodeConfig?.ortho || [];
    serviceLinesList?.forEach((serviceLine) => {
      const isSupported =
        orthoCodes.includes(serviceLine.procedure) &&
        !serviceLine?.resultCode?.includes(unsupportedDecisionCodes);

      isSupported
        ? supportedServiceLines.push(serviceLine)
        : unSupportedServiceLines.push(serviceLine);
    });

    return [supportedServiceLines, unSupportedServiceLines];
  }

  enhanceServiceLineDetails(serviceLine: ServiceLineObject) {
    serviceLine.dateOfService = formatDate(serviceLine.dateOfService);
    const decision = serviceLine.review?.ai?.decision;
    let pendCode = decision?.code;
    serviceLine.resultCodeColor = getColorMatchedWithResult(
      decision?.result,
      "",
      pendCode
    );
    serviceLine.resultCode = userService.getOverjetReviewCodeDisplayAlteration()
      ? getConvertedOverjetReviewCode(decision?.code)
      : decision?.code;

    serviceLine.resultCodeIcon = getOJRRIcon(decision?.result);
    serviceLine.resultDescription = getReviewResult(decision?.result);
    return serviceLine;
  }

  getTemplateImages() {
    const orthoGridMappings =
      this.canvasInformation.claimPrediction?.image_paths || {};

    const imageTypeOrder = [
      "face",
      "other",
      orthoImageTypeToHyphenated(ORTHO_IMAGE_TYPE.INTRA_OCCLUSAL_UPPER),
      orthoImageTypeToHyphenated(ORTHO_IMAGE_TYPE.INTRA_FRONTAL_REST),
      orthoImageTypeToHyphenated(ORTHO_IMAGE_TYPE.INTRA_OCCLUSAL_LOWER),
      orthoImageTypeToHyphenated(ORTHO_IMAGE_TYPE.INTRA_LATERAL_RIGHT),
      orthoImageTypeToHyphenated(ORTHO_IMAGE_TYPE.INTRA_FRONTAL_OCCLUSION),
      orthoImageTypeToHyphenated(ORTHO_IMAGE_TYPE.INTRA_LATERAL_LEFT),
      orthoImageTypeToHyphenated(ORTHO_IMAGE_TYPE.PAN),
      orthoImageTypeToHyphenated(ORTHO_IMAGE_TYPE.CEPH),
    ];

    const allImages: OrthoImageObj = {
      ...Object.fromEntries(
        Object.entries(this.canvasInformation.croppedImages ?? {})
          .sort((a, b) => this.compareImageTypes(a, b, imageTypeOrder))
          .map(([key, value]) => [
            key,
            {
              type: value?.imageType ?? "junk",
              sourceImage: value?.sourceImage,
              thumbnail: value?.imageThumbnailUri ?? value?.sourceImage,
            },
          ])
      ),
      ...Object.fromEntries(
        Object.entries(this.canvasInformation?.sourceImages ?? {})
          .sort((a, b) => this.compareImageTypes(a, b, imageTypeOrder))
          .map(([key, value]) => [
            key,
            {
              type: value?.imageType ?? "junk",
              sourceImage: value?.sourceImage,
              thumbnail: value?.imageThumbnailUri ?? value?.sourceImage,
            },
          ])
      ),
    };

    this.updateImageTypes(allImages, orthoGridMappings);
    const templateIntraImages = this.mapImagesToGrid(orthoGridMappings);
    const templateNonIntraImages =
      this.mapNonIntraImagesToGrid(orthoGridMappings);

    const sortedAllTemplateImages = this.getSortedTemplateImages(
      templateIntraImages,
      templateNonIntraImages
    );

    const { showIntraImages, showNonIntraImages } = this.getImagesToShow(
      templateIntraImages,
      templateNonIntraImages
    );

    const additionalImages = this.getAdditionalImages(
      allImages,
      sortedAllTemplateImages,
      imageTypeOrder
    );

    const imagesQueue = this.createImagesQueue(
      sortedAllTemplateImages,
      additionalImages
    );

    return {
      allImages,
      sortedAllTemplateImages,
      showIntraImages,
      showNonIntraImages,
      templateIntraImages,
      templateNonIntraImages,
      additionalImages,
      imagesQueue,
    };
  }

  updateImageTypes(allImages: OrthoImageObj, orthoGridMappings: ImagePaths) {
    Object.entries(ORTHO_IMAGE_TYPE).forEach(([key, imageType]) => {
      const imageInfo = orthoGridMappings[imageType];
      if (imageInfo?.img_id) {
        allImages[imageInfo.img_id].type = imageType;
      }
    });
  }

  mapImagesToGrid(orthoGridMappings: ImagePaths) {
    return [
      [
        ORTHO_IMAGE_TYPE.INTRA_OCCLUSAL_UPPER,
        ORTHO_IMAGE_TYPE.INTRA_FRONTAL_REST,
        ORTHO_IMAGE_TYPE.INTRA_OCCLUSAL_LOWER,
      ],
      [
        ORTHO_IMAGE_TYPE.INTRA_LATERAL_RIGHT,
        ORTHO_IMAGE_TYPE.INTRA_FRONTAL_OCCLUSION,
        ORTHO_IMAGE_TYPE.INTRA_LATERAL_LEFT,
      ],
    ].map((row) => row.map((key) => orthoGridMappings[key]?.img_id ?? ""));
  }

  mapNonIntraImagesToGrid(orthoGridMappings: ImagePaths) {
    return [ORTHO_IMAGE_TYPE.PAN, "", ORTHO_IMAGE_TYPE.CEPH].map(
      (key) => orthoGridMappings[key as keyof ImagePaths]?.img_id ?? ""
    );
  }

  getSortedTemplateImages(
    templateIntraImages: string[][],
    templateNonIntraImages: string[]
  ) {
    return [
      ...templateIntraImages[0],
      ...templateIntraImages[1],
      ...templateNonIntraImages,
    ].filter(Boolean);
  }

  getImagesToShow(
    templateIntraImages: string[][],
    templateNonIntraImages: string[]
  ) {
    const showIntraImages = !templateIntraImages.every((row) =>
      row.every((img) => img === "")
    );

    const showNonIntraImages = !templateNonIntraImages.every(
      (img) => img === ""
    );

    return { showIntraImages, showNonIntraImages };
  }

  getAdditionalImages(
    allImages: OrthoImageObj,
    sortedAllTemplateImages: string[],
    imageTypeOrder: string[]
  ): string[] {
    return Object.keys(allImages).filter(
      (imageName) => !sortedAllTemplateImages.includes(imageName)
    );
  }

  createImagesQueue(
    sortedAllTemplateImages: string[],
    additionalImages: string[]
  ) {
    return [...sortedAllTemplateImages, ...additionalImages].filter(Boolean);
  }

  compareImageTypes(
    [imageAKey, imageA]: [string, OrthoImageDetails | GenericObject],
    [imageBKey, imageB]: [string, OrthoImageDetails | GenericObject],
    imageTypeOrder: string[]
  ): number {
    const typeA = imageA?.imageType ?? "";
    const typeB = imageB?.imageType ?? "";

    const typeAIndex = imageTypeOrder.indexOf(typeA);
    const typeBIndex = imageTypeOrder.indexOf(typeB);

    if (typeAIndex === -1 && typeBIndex === -1) {
      return 0; // Both are unknown types, keep original order
    } else if (typeAIndex === -1) {
      return 1; // typeA is unknown, push it to the end
    } else if (typeBIndex === -1) {
      return -1; // typeB is unknown, push it to the end
    } else {
      return typeAIndex - typeBIndex; // Both are known types, sort by order
    }
  }
}
